<!-- 用户上传视频，寻医列表 -->
<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>用户上传视频</el-breadcrumb-item>
            <el-breadcrumb-item>寻医视频列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="video_id" label="ID"></el-table-column>
            <el-table-column label="标题" prop="name"></el-table-column>
            <el-table-column label="简介" prop="desc"></el-table-column>
            <el-table-column prop="comment_count" label="评论数"></el-table-column>
            <el-table-column prop="view_count" label="阅读数"></el-table-column>
            <el-table-column prop="like_count" label="点赞数"></el-table-column>
            <el-table-column width="300px">
                <template slot-scope="scope">
                    <div class="opt doc">
                    <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit('bj',scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除 </el-button>
                    <el-button size="mini" type="warning" icon="el-icon-view" @click="lookpl(scope.row)">查看评论</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <xylookpl :showpl.sync="showpl" :plid="plid" :from="from"></xylookpl>
    </div>
</template>
<script>
    import xylookpl from '../components/xylookpl.vue'
    export default {
        components: {
            xylookpl
        },
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                addinhzbl: false,
                radio: '0',
                addhzbl: {},
                showpl: false,
                plid: '',
                from: 'xy'
            }
        },
        mounted() {
            this.getdata(1);
        },
        methods: {
            getdata(page) {
                this.axios.get('/xyvideo/get_gu_xy_videos?page=' + page + "&size=" + this.PageSize)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.list = res.data.result;
                            this.totalCount = res.data.count;
                        }
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage);
            },
            //编辑
            edit(type, row) {
                this.$router.push({ path: '/xyvideo', query: { id: row.video_id,type:'bj' } });
            },
            //删除
            del(row, index) {
                this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/xyvideo/delete_gu_xy_video", this.qs.stringify({ id: row.video_id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //查看评论
            lookpl(row) {
                this.showpl = true;
                this.plid = row.video_id;
            }
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
        float: left;
    }

    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
</style>